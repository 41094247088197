import React from 'react';
import type { CSSProp } from 'styled-components';

export * from './invoice';
export * from './acl';
export * from './audience';
export * from './account';
export * from './accountHealth';
export * from './activity';
export * from './audience';
export * from './brands';
export * from './campaigns';
export * from './cmpreports';
export * from './discount';
export * from './events';
export * from './forecast';
export * from './geo';
export * from './integrations';
export * from './invoice_legacy';
export * from './loyaltyTemplates';
export * from './marketanalytics';
export * from './missionControl';
export * from './models';
export * from './recipes';
export * from './service';
export * from './twilio';
export * from './users';
export * from './userAlerts';
export * from '../pages/crew/crew-types/index';
export * from '../pages/surveysAndReviews/survey-types';
export * from '../components/global/types';
export * from '../pages/legacyPersonas/persona-types';
export * from './contexts';
export * from './form';
export * from './telnyx';
export * from './customer-chat';
export * from './user-settings';
export * from './points-freeze-windows';
export * from './signupForms';

export type UserBlacklist = {
	[key: string]: number[];
};

export type Setter<T> = Partial<T> | ((curr: T) => Partial<T>);

export interface LoaderState {
	loading?: boolean;
	loaded?: boolean;
}

export type LoaderSetter = React.Dispatch<
	React.SetStateAction<{
		loading: boolean;
		loaded: boolean;
	}>
>;

export type ID = string | number;

export type EntryData<T> = {
	ids: (string | number)[]; // Used to keep the order of the entries
	entries: { [id: ID]: T };
};

export type EntryMap<T> = { [id: ID]: T };

export type EntryDataLoader<T> = EntryData<T> & {
	loaded?: boolean;
};

type InputArgs<T> = [filterCB?: (i: T, key: number) => boolean, mapCb?: (i: T, key: number) => T];

export function createEntryMap<T extends { id?: string | number | undefined | any }>(items: T[], ...args: InputArgs<T>): EntryMap<T> {
	const entries: { [id: string]: T } = {};

	const filterCB = args[0];
	const mapCB = args[1];

	let key = 0;
	for (const item of items) {
		if (!item.id) continue;

		if (filterCB && !filterCB(item, key)) continue;
		if (mapCB) entries[item.id] = mapCB(item, key);
		else entries[item.id] = item;
		key++;
	}

	return entries;
}

export function setValidID<T>(item: T & { id?: ID }, filterCB?: (i: T) => boolean): { [id: string]: T } {
	if (item.id === undefined || (filterCB && !filterCB(item))) return {};
	return { [item.id]: item };
}

export function createEntryData<T extends { id?: string | number | undefined | any }>(
	items: T[],
	cb?: (item: T) => T,
	filterCb?: (item: T) => boolean,
): EntryData<T> | EntryDataLoader<T> {
	const ids: (string | number)[] = [];
	const entries: { [id: string | number]: T } = {};

	for (const item of items) {
		if (!item.id) continue;
		ids.push(item.id);
		const isValid = filterCb ? filterCb(item) : true;
		if (!isValid) continue;
		entries[item.id] = cb?.(item) ?? item;
	}

	return { loaded: true, ids, entries };
}

export type DropdownOption = { label: string | React.ReactNode; value: string | number };

declare module 'react' {
	interface Attributes {
		css?: CSSProp | undefined;
	}
}
